import { Close, IconButton, useIsMinWidth } from "@app/design-system";
import styled from "styled-components";
import makeTestId from "../../../utils/makeTestId";
import { useMapInteractionsContext } from "../../map/MapInteractions/MapInteractionsProvider";
import SpatialPopupPagination from "./SpatialPopupPagination";

const StyledMapPopupView = styled.div`
  display: grid;
  gap: 0.5rem;
`;

const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  ${(p) => p.theme.typography.variants.subtitleMd}
`;

const StyledActions = styled.div`
  display: flex;
  gap: 0.125rem;
  margin-left: auto;
`;

interface MapPopupViewProps {
  children?: React.ReactNode;
  disablePagination?: boolean;
  "data-testid"?: string;
  hasClose?: boolean;
  header?: React.ReactNode;
  onClose: () => void;
}

const MapPopupView = ({
  children,
  "data-testid": dataTestId,
  disablePagination,
  hasClose = true,
  header,
  onClose,
}: MapPopupViewProps) => {
  const isTabletLandscapeAndAbove = useIsMinWidth("md");

  const showCloseButton = !!hasClose || !isTabletLandscapeAndAbove;

  const {
    activateClickState,
    clickedState: { allFeatures, properties },
  } = useMapInteractionsContext();

  const hasOtherFeatures = allFeatures && allFeatures.length > 1;

  return (
    <StyledMapPopupView data-testid={dataTestId}>
      {(header || showCloseButton) && (
        <StyledHeader data-testid={makeTestId(dataTestId, "header")}>
          {header}
          {showCloseButton && (
            <StyledActions>
              {!disablePagination && hasOtherFeatures && properties && (
                <SpatialPopupPagination
                  allFeatures={allFeatures}
                  featureId={properties.featureId}
                  onPaginate={(next) => {
                    activateClickState({
                      allFeatures,
                      layerId: next.layerId,
                      properties: next.properties,
                    });
                  }}
                />
              )}
              {showCloseButton && (
                <IconButton
                  data-testid={makeTestId(dataTestId, "close-button")}
                  icon={Close}
                  label="Close"
                  onClick={onClose}
                  size="xs"
                  variant="ghost"
                />
              )}
            </StyledActions>
          )}
        </StyledHeader>
      )}
      {children}
    </StyledMapPopupView>
  );
};

export default MapPopupView;
