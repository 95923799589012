import { Checkbox, Field } from "@app/design-system";
import styled from "styled-components";
import type {
  LayerConfig,
  LayerWithSubLayers,
} from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";
import type { ActiveLayerState } from "../../util/ActiveLayersProvider/useActiveLayers";
import LayerOpacitySlider from "./LayerOpacitySlider";
import LegendToggleButton from "./LegendToggleButton";
import MenuCheckbox from "./MenuCheckbox";

const StyledLayerOptionList = styled.div`
  display: grid;
  gap: 0.5rem;
  padding: 0 0 0.5rem 1.5rem;
`;

type GenericLayerState = ActiveLayerState & {
  [key: string]: ActiveLayerState & { isActive: boolean };
};

interface LayerGroupCheckboxListProps {
  layer: LayerWithSubLayers;
}

const LayerGroupCheckboxList = ({ layer }: LayerGroupCheckboxListProps) => {
  const {
    activateLayer,
    deactivateLayer,
    getLayerState,
    isLayerActive,
    setLayerState,
  } = useActiveLayersContext();

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.currentTarget.checked) {
      const state = Object.fromEntries(
        layer.subLayers?.map((subLayer) => [subLayer.id, { isActive: true }]) ??
          [],
      );
      activateLayer({ id: layer.id, source: "layers-drawer", state });
    } else {
      deactivateLayer({ id: layer.id });
    }
  };

  const state = getLayerState<GenericLayerState>(layer.id);

  const onSubLayerChange =
    (subLayer: LayerConfig) => (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.currentTarget.checked) {
        setLayerState({
          id: layer.id,
          source: "layers-drawer",
          state: {
            [subLayer.id]: { isActive: true, opacity: state?.opacity ?? 1 },
          },
        });
      } else {
        const isEverySubLayerUnchecked = layer.subLayers?.every((s) => {
          return s.id === subLayer.id || !state?.[s.id]?.isActive;
        });

        if (isEverySubLayerUnchecked) {
          deactivateLayer({ id: layer.id });
        } else {
          setLayerState({
            id: layer.id,
            source: "layers-drawer",
            state: { ...state, [subLayer.id]: undefined },
          });
        }
      }
    };

  const onClose = () => {
    if (isLayerActive(layer.id)) {
      setLayerState({
        id: layer.id,
        source: "layers-drawer",
        state: { opacity: 1 },
      });
    }
  };

  return (
    <>
      <MenuCheckbox
        actions={
          layer.legend && (
            <LegendToggleButton legend={layer.legend} variant="ghostWeak" />
          )
        }
        checked={isLayerActive(layer.id)}
        defaultIsOpen={state ? state.opacity !== 1 : false}
        disabled={layer.disabled}
        label={layer.label}
        onChange={onChange}
        onClose={onClose}
      >
        <Field label="Opacity">
          <LayerOpacitySlider
            onChange={(value) => {
              setLayerState({
                id: layer.id,
                source: "layers-drawer",
                state: { opacity: value / 100 },
              });
            }}
            {...(state && { value: state.opacity * 100 })}
          />
        </Field>
      </MenuCheckbox>
      <StyledLayerOptionList>
        {layer.subLayers.map((subLayer) => {
          return (
            <Checkbox
              checked={!!state?.[subLayer.id]?.isActive}
              disabled={layer.disabled || subLayer.disabled}
              key={subLayer.id}
              label={subLayer.label}
              onChange={onSubLayerChange(subLayer)}
              data-testid={`${subLayer.id}-checkbox`}
            />
          );
        })}
      </StyledLayerOptionList>
    </>
  );
};

export default LayerGroupCheckboxList;
