import {
  Info,
  InfoFilled,
  ToggleIconButton,
  type IconButtonVariant,
  type IconButtonSize,
} from "@app/design-system";
import React from "react";
import type { Legend } from "../../../config/layers/layers";
import { useActiveLayersContext } from "../../util/ActiveLayersProvider/ActiveLayersProvider";

interface LegendToggleButtonProps {
  legend: Legend;
  size?: IconButtonSize;
  variant?: IconButtonVariant;
}

const LegendToggleButton = ({
  legend,
  size = "xs",
  variant = "ghost",
}: LegendToggleButtonProps) => {
  const {
    setActiveLegend,
    state: { activeLegend },
  } = useActiveLayersContext();

  const selected = activeLegend?.id === legend.id;

  return (
    <ToggleIconButton
      iconOn={InfoFilled}
      iconOff={Info}
      label="Legend"
      onClick={() => {
        if (activeLegend?.id === legend.id) {
          setActiveLegend({ legend: null });
          return;
        }
        setActiveLegend({ legend });
      }}
      selected={selected}
      size={size}
      variant={variant}
    />
  );
};

export default LegendToggleButton;
