import { Slider } from "@app/design-system";
import { useState, useTransition } from "react";
import styled from "styled-components";

const StyledLayerOpacitySlider = styled.div`
  padding: 0.25rem 0.75rem 0 0;
`;

interface LayerOpacitySliderProps {
  isDisabled?: boolean;
  onChange: (value: number) => void;
  value?: number;
}

const LayerOpacitySlider = ({
  isDisabled,
  onChange,
  value: externalValue,
}: LayerOpacitySliderProps) => {
  const [value, setValue] = useState(externalValue ?? 100);
  const [isPending, startTransition] = useTransition();

  if (!isPending && value !== externalValue && externalValue !== undefined) {
    setValue(externalValue);
  }

  return (
    <StyledLayerOpacitySlider>
      <Slider
        isDisabled={isDisabled}
        minValue={0}
        maxValue={100}
        onChange={(nextValue) => {
          if (Array.isArray(nextValue)) return;

          setValue(nextValue);

          startTransition(() => {
            onChange(nextValue);
          });
        }}
        value={value}
      />
    </StyledLayerOpacitySlider>
  );
};

export default LayerOpacitySlider;
