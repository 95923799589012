import type { Feature } from "geojson";
import type { MapLayerMouseEvent } from "maplibre-gl";
import { useContext, useEffect } from "react";
import type { MapboxMouseEvent } from "../types";
import { MapInteractionsContext } from "./MapInteractionsProvider";
import type {
  FeatureInteractionProperties,
  InteractiveMapElement,
  MapInteractionType,
} from "./types";
import useLayerInteractionState from "./useLayerInteractionState";

interface UseLayerInteractionsParams<P extends FeatureInteractionProperties> {
  element?: InteractiveMapElement;
  getPropertiesFromFeature: (
    feature: Feature,
    event: MapboxMouseEvent | MapLayerMouseEvent,
  ) => P | null;
  layerId: string;
  type?: MapInteractionType;
}

const useLayerInteractions = <P extends FeatureInteractionProperties>({
  element = "feature",
  getPropertiesFromFeature,
  layerId,
  type = "popup",
}: UseLayerInteractionsParams<P>) => {
  const context = useContext(MapInteractionsContext);

  if (context === undefined) {
    throw new Error(
      "useLayerInteractions must be used within a MapInteractionsProvider",
    );
  }

  const { register, unregister } = context;

  useEffect(() => {
    register({
      layer: { getPropertiesFromFeature, layerId, element },
      type,
    });

    return () => {
      unregister({ layerId, type });
    };
  }, [element, getPropertiesFromFeature, layerId, register, type, unregister]);

  const interactionState = useLayerInteractionState<P>({ layerId, type });

  return interactionState;
};

export default useLayerInteractions;
